import { Container } from "@mui/material";
import { Link } from "react-router-dom"
import Grid from "@mui/material/Grid";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useCentroStore } from "../store";
import HeaderSection from "./header/HeaderSection";
import { useClearState } from "./Helpers/HelperFunctions";
//import UserListTables from "./UserListTables";
import RoleListTables from "./RoleListTables";
import { useUser, useRole, useAllRoles, useAllFacilityTypes } from "../store/queries/userQueries";
import { Dialog, DialogTitle, DialogContent, Typography, Button, DialogActions, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, TextField, Select, MenuItem, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const RoleList = (props) => {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const userId = useCentroStore((state) => state.userId);
    const { userData } = useUser(userId);
    const { addRole } = useRole();
    const userDataPermissions = userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0];
    const { data: rolesData, refetch, isLoading } = useAllRoles();
    const { data: FacilityTypeData } = useAllFacilityTypes();
    //const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const [currentRole, setCurrentRole] = React.useState(null);
    const [reportsToRoles, setReportsToRoles] = React.useState(null);
    const newRole = useCentroStore((state) => state.userRole);
    const newPermissions = useCentroStore((state) => state.userPermissions);
    const [errorCode, setErrorCode] = React.useState(0);
    const [errorMsg, setErrorMsg] = React.useState("");

    const handleOpen = () => {
        if (userDataPermissions.createRole === 1) {
            var blankRole = newRole;
            blankRole.permissions = []; // Resetting permissions array
            blankRole.permissions.push(newPermissions);
            setCurrentRole(blankRole);
            // Creates list of all roles for reports To field
            setReportsToRoles(rolesData);
            setOpen(true);
        }
        else {
            alert("You do not have permission to add roles.");
        }
    };
    const handleClose = () => {
        setErrorCode(0);
        setCurrentRole(null);
        setOpen(false);
    };

    const handleInputChange = (event, property) => {
        setCurrentRole((prev) => ({ ...prev, [property]: event.target.value }));
    };

    const handleSave = async () => {
        //console.log(currentRole); // to log the updated role data
        let response;
        response = await addRole.mutateAsync(currentRole).catch(function (error) {
            setErrorCode(error.response.status);
            if (error.response.status === 400) {
                setErrorMsg("There was an error creating a New Role. Required fields are missing.");
            }
            else {
                setErrorMsg(error.message);
            }
        });
        if (response.status === 200) {
            setErrorCode(0);
            refetch(); // Refetch query to update datagrid
            alert("Role has been successfully created.");// Successful save message
        }
        else {
            setErrorCode(response.status);
            setErrorMsg(response.statusText);
            alert("There was an error creating New Role. Role could not be created.");// Error
        }
        handleClose();
    };

    const handleFullAccessRadioChange = (event, property) => {
        setCurrentRole((prev) => ({ ...prev, [property]: event.target.value }));
    };

    const handleRadioChange = (event, property) => {
        var tempPermissions = [...currentRole.permissions]
        tempPermissions[0][property] = event.target.value;
        setCurrentRole((prev) => ({ ...prev, ['permissions']: tempPermissions }));
    };

    const permissionSubSections = {
        //'Facilities:': ['createFac', 'readFac', 'updateFac', 'deleteFac'],
        //'Users:': ['createUser', 'readUser', 'updateUser', 'deleteUser'],
        //'Roles:': ['createRole', 'readRole', 'updateRole', 'deleteRole'],
        //'Reports:': ['createReport', 'readReport', 'updateReport', 'deleteReport', 'approveReport', 'escalateReport', 'approveEscalatedReport']
        'Facilities:': ['createFac', 'readFac', 'updateFac', 'deleteFac'],
        'Users:': ['createUser', 'readUser', 'updateUser', 'deleteUser'],
        'Roles:': ['createRole', 'readRole', 'updateRole'],
        'Reports:': ['createReport', 'approveReport', 'escalateReport', 'approveEscalatedReport'],
        'Analytics': ['displayAnalytics']
    };

    const permissionSubSectionsSpanish = {
        'Instalaciones:': ['createFac', 'readFac', 'updateFac', 'deleteFac'],
        'Usuarios:': ['createUser', 'readUser', 'updateUser', 'deleteUser'],
        'Roles:': ['createRole', 'readRole', 'updateRole'],
        'Informes:': ['createReport', 'approveReport', 'escalateReport', 'approveEscalatedReport'],
        'Anal\xEDtica': ['displayAnalytics']
    };

    const permissionDisplayLabels = {
        /*'fullAccess': 'Full Access:',
        'createFac': 'Create Facility:',
        'readFac': 'Read Facility:',
        'updateFac': 'Update Facility:',
        'deleteFac': 'Deactivate Facility:',
        'createUser': 'Create User:',
        'readUser': 'Read User:',
        'updateUser': 'Update User:',
        'deleteUser': 'Deactivate User:',
        'createRole': 'Create Role:',
        'readRole': 'Read Role:',
        'updateRole': 'Update Role:',
        'deleteRole': 'Delete Role:',
        'createReport': 'Create Report:',
        'readReport': 'Read Report:',
        'updateReport': 'Update Report:',
        'deleteReport': 'Delete Report:',
        'approveReport': 'Approve Report:',
        'escalateReport': 'Escalate Report:',
        'approveEscalatedReport': 'Approve Escalated Report:'*/
        'fullAccess': 'Full Access: (Read-Only)',
        'createFac': 'Create Facility:',
        'readFac': 'Read Facility:',
        'updateFac': 'Update Facility:',
        'deleteFac': 'Deactivate Facility:',
        'createUser': 'Create User:',
        'readUser': 'Read User:',
        'updateUser': 'Update User:',
        'deleteUser': 'Deactivate User:',
        'createRole': 'Create Role:',
        'readRole': 'Read Role:',
        'updateRole': 'Update Role:',
        'createReport': 'Create Report:',
        'approveReport': 'Approve Report:',
        'escalateReport': 'Escalate Report:',
        'approveEscalatedReport': 'Approve Escalated Report:',
        'displayAnalytics': 'View Analytics:'
    };

    const permissionDisplayLabelsSpanish = {
        'fullAccess': 'Acceso Completo: (Solo-Lectura)',
        'createFac': 'Crear Instalaci\xF3n:',
        'readFac': 'Instalaci\xF3n de Lectura:',
        'updateFac': 'Instalaci\xF3n de Actualizaci\xF3n:',
        'deleteFac': 'Desactivar Instalaci\xF3n:',
        'createUser': 'Crear Usuario:',
        'readUser': 'Leer Usuario:',
        'updateUser': 'Actualizar Usuario:',
        'deleteUser': 'Desactivar Usuario:',
        'createRole': 'Crear Rol:',
        'readRole': 'Leer Rol:',
        'updateRole': 'Actualizar Rol:',
        'createReport': 'Crear Informe:',
        'approveReport': 'Aprobar Informe:',
        'escalateReport': 'Escalar Informe:',
        'approveEscalatedReport': 'Aprobar Informe Escalado:',
        'displayAnalytics': 'Puede Ver Datos Anal\xEDticos'
    };

    const modalBody = currentRole ? (
        <Dialog className="role-modal" open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ textTransform: "capitalize" }}>{EnglishLanguage ? "Add New Role" : "Agregar Nuevo Rol"}</DialogTitle>
            <DialogContent sx={{ minWidth: "500px" }}>
                <FormControl variant="standard" fullWidth margin="normal">
                    <Typography>{EnglishLanguage ? "Name" : "Nombre"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                    <TextField
                        label={EnglishLanguage ? "Name" : "Nombre"}
                        value={currentRole.name}
                        onChange={(event) => handleInputChange(event, 'name')}
                        variant="standard"
                        fullWidth
                        margin="normal"
                    />
                </FormControl>
                <FormControl variant="standard" fullWidth margin="normal">
                    <Tooltip title={EnglishLanguage ? "Assign which Facility Type this role belongs to. If there are no Types, they will need to be created in the Facility Types page."
                        : "Asigne a qu\xE9 tipo de instalaci\xF3n pertenece este rol. Si no hay tipos, deber\xE1n crearse en la p\xE1gina Tipos de instalaciones."} arrow>
                        <Typography>{EnglishLanguage ? "Facility Type" : "Tipo de Facilidad"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                    </Tooltip>
                    <Select variant="filled" defaultValue={""} onChange={(event) => handleInputChange(event, 'facilityTypeId')} >
                        <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Type" : "Seleccione Tipo"}</em></MenuItem>
                        {FacilityTypeData.map((type) => (<MenuItem key={type.typeId} value={type.typeId}>{type.typeName}</MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl variant="standard" fullWidth margin="normal">
                    <Tooltip title={EnglishLanguage ? "Assign higher role that this role reports to. Leave blank if this role reports to no one."
                        : "Asigne un rol superior al que este rol reporta. Deje en blanco si este rol no depende de nadie."} arrow>
                        <Typography><br />{EnglishLanguage ? "Reports To: (Optional)" : "Reporta A: (Opcional)"}</Typography>
                    </Tooltip>
                    <Select variant="filled" defaultValue={null} onChange={(event) => handleInputChange(event, 'reportsTo')} >
                        <MenuItem value={null}><em>{EnglishLanguage ? "None" : "Ninguno"}</em></MenuItem>
                        {reportsToRoles.map((role) => (<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>))}
                    </Select>
                </FormControl>
                <FormLabel component="h3" sx={{ margin: "2rem 0 0rem", padding: "0", textTransform: "uppercase", color: "#3d556c" }}>{EnglishLanguage ? "Permissions" : "Permisos"}<Tooltip title="Required"><span className="validation"> *</span></Tooltip></FormLabel>
                <FormControl component="fieldset">
                    <Tooltip title={EnglishLanguage ? "Full Access grants all permissions to this role. Only assigned for pre-set administrator roles."
                        : "El acceso completo otorga todos los permisos para este rol. Solo asignado para roles de administrador preestablecidos."} arrow>
                        <Typography><br />{EnglishLanguage ? permissionDisplayLabels['fullAccess'] : permissionDisplayLabelsSpanish['fullAccess']}</Typography>
                    </Tooltip>
                    <RadioGroup
                        row
                        value={0}
                    //onChange={(event) => handleFullAccessRadioChange(event, 'fullAccess')}
                    >
                        <FormControlLabel value={1} control={<Radio />} label={EnglishLanguage ? "Allow" : "Conceder"} />
                        <FormControlLabel value={0} control={<Radio />} label={EnglishLanguage ? "Deny" : "Negar"} />
                    </RadioGroup>
                </FormControl>
                {Object.entries(EnglishLanguage ? permissionSubSections : permissionSubSectionsSpanish).map(([section, permissions]) => (
                    <div key={section}>
                        <Typography><br />{section}</Typography>
                        {permissions.map((permission) => (
                            <FormControl className="role-radios" key={permission} component="fieldset">
                                <FormLabel component="h4" sx={{ margin: "1rem 0 0rem", padding: "0" }}>{EnglishLanguage? permissionDisplayLabels[permission] : permissionDisplayLabelsSpanish[permission]}</FormLabel>
                                <RadioGroup
                                    row
                                    defaultValue={currentRole.permissions[0][permission] === 0 ? 0 : 1}
                                    onChange={(event) => handleRadioChange(event, permission)}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label={EnglishLanguage ? "Allow" : "Conceder"} />
                                    <FormControlLabel value={0} control={<Radio />} label={EnglishLanguage ? "Deny" : "Negar"} />
                                </RadioGroup>
                            </FormControl>
                        ))}
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                {errorCode !== 0 ? <Grid container justifyContent="center" style={{ color: 'red' }}><Grid item pt={0} pb={2} mb={-2}>{errorMsg}</Grid></Grid> : null}
                <Button onClick={handleClose} color="primary">{EnglishLanguage ? "Cancel" : "Cancelar"}</Button>
                <Button onClick={handleSave} color="primary">{EnglishLanguage ? "Save" : "Guardar"}</Button>
            </DialogActions>
        </Dialog>
    ) : null;


  return (
    <Container component="main" maxWidth="none" id="incident-reports" sx={{padding: "0!important"}}>
      {modalBody}
      <HeaderSection className="content-wrap" sectionName="Roles" sectionNameES="roles"/>
      <Grid
       className="page-wrap"
        container
        xs={12}
        mr={2}
        sx={{ position: "relative", left: ".5rem" }}
      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}
          sx={{ height: "calc(100vh - 7.9rem)" }}
        >
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                {EnglishLanguage ? ("Role List") : ("lista de roles")}
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            >
              <Item elevation={0} sx={{ background: "transparent" }} onClick={handleOpen} >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "500" }}
                    mb={3}
                    mt={1.25}
                    className="add-text-ir"
                  >
                    {EnglishLanguage ? "Add Role" : "Agregar rol"}
                  </Typography>{" "}
              </Item>

              <Item
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                  top: "9px",
                  right: "10px",
                  position: "relative",
                }} 
                onClick={handleOpen}
              >
                  <PersonAddIcon className="add-todo-icon" />
              </Item>
            </Grid>
          </Grid>
          <Grid lg={12} px={2} mt={3} sx={{ height: "calc(100% - 66px)" }}>
            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
              <RoleListTables />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RoleList;
