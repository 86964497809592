import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    fetchICAOCountryCodes, fetchICAOIncidents, fetchICAOIncidentsModelData, fetchICAOIncidentsLocationData, fetchTowerOpsData, fetchTRACONOpsData
} from '../api/apiEndpoints/apiEndpoints';


export function useDataAnalytics(year) {
    const queryClient = useQueryClient();
    
    const icaoCountryCodes = useQuery("icaoCountryCodes", fetchICAOCountryCodes, { refetchOnWindowFocus: false, staleTime: Infinity });
    /*
    const icaoIncidents = useQuery("icaoIncidents", fetchICAOIncidents, { refetchOnWindowFocus: false, staleTime: Infinity });
    const towerOpsData = useQuery("towerOps", fetchTowerOpsData, { refetchOnWindowFocus: false, staleTime: Infinity });
    const traconOpsData = useQuery("traconOps", fetchTRACONOpsData, { refetchOnWindowFocus: false, staleTime: Infinity });
    */

    const icaoIncidentsModelData = useQuery(['icaoIncidentModels', year], () => fetchICAOIncidentsModelData(year), { refetchOnWindowFocus: false, staleTime: Infinity });
    const icaoIncidentsLocationData = useQuery(['icaoIncidentLocations', year], () => fetchICAOIncidentsLocationData(year), { refetchOnWindowFocus: false, staleTime: Infinity });

    return { icaoCountryCodes, icaoIncidentsModelData, icaoIncidentsLocationData };
}