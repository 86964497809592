import "./App.css";
import LoginForm from "./components/forms/LoginForm";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Home from "./components/Home";
import Settings from "./components/Settings";
import Training from "./components/Training";
import IncidentReports from "./components/IncidentReports";
import Maps from "./components/Maps";
import MenuSetup from "./components/MenuSetup";
import Technical from "./components/Technical";
import SetUp from "./components/SetUp";
import FAQ from "./components/FAQ";
import CreateNewIncidentReport from "./components/CreateNewIncidentReport";
import Analytics from "./components/Analytics";
import Navbar from "./components/NavBar";
import { createContext, Suspense, useEffect, useState } from "react";
import { Backdrop, Grid } from "@mui/material";
import { useCentroStore } from "./store";
import { useUser } from "./store/queries/userQueries";
import SpeedDialTooltipOpen from "./components/SpeedDialTooltipOpen";
import MediaQuery from "react-responsive";
import FixedBottomNavigation from "./components/NavBar/FixedBottomNavigation";
import UserSettings from "./components/UserSettings";
import TipsComponent from "./components/TipsComponent";
import UserProfile from "./components/UserProfile";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import SessionTimeout from "./components/SessionTimer/SessionTimeout";
import { useQueryClient } from "react-query";
import { RefreshToken } from "./components/SessionTimer/TokenRefreshTimer";
import UserAdmin from "./components/UserList";
import UserList from "./components/UserList";
import RoleList from "./components/RoleList";
import FacilityList from "./components/FacilityList";
import FacilityType from "./components/FacilityType";


export const ThemeContext = createContext(null);


function RouteStatus({ onRouteChange }) {
  const location = useLocation();

  useEffect(() => {
    onRouteChange(location.pathname);
  }, [location.pathname, onRouteChange]);

  return null;
}

function App() {
  //const userloggedin = useCentroStore((state) => state.userloggedin);
  const isAuthenticated = useCentroStore((state) => state.isAuthenticated);
  const setAuth = useCentroStore((state) => state.setAuth);
  const setUserId = useCentroStore((state) => state.setUserId);
  const queryClient = useQueryClient();
  const [serverText, setServerText] = useState('Local Server Default'); // Default to Local Server
  const setCurrentUserPreferences = useCentroStore((state) => state.setCurrentUserPreferences);
  const setEnglishLanguage = useCentroStore((state) => state.setEnglishLanguage);
  const userId = useCentroStore((state) => state.userId);
  const { userData } = useUser(userId);
  const setActiveRole = useCentroStore((state) => state.setActiveRole);
  const setUserPreferencesUserId = useCentroStore((state) => state.setUserPreferencesUserId);
  const setUserPreferencesPreferredLanguage = useCentroStore((state) => state.setUserPreferencesPreferredLanguage);
  const setUserPreferencesDefaultFacilityId = useCentroStore((state) => state.setUserPreferencesDefaultFacilityId);
  const setUserPreferencesDisplayReportsFirst = useCentroStore((state) => state.setUserPreferencesDisplayReportsFirst);
  const setUserPreferencesDisplayAnalyticsFirst = useCentroStore((state) => state.setUserPreferencesDisplayAnalyticsFirst);
  const setUserPreferencesWeatherTempMetric = useCentroStore((state) => state.setUserPreferencesWeatherTempMetric);
  const setTemperature = useCentroStore((state) => state.setWeatherTempMetric);
  const setUserPreferencesWindSpeedMetric = useCentroStore((state) => state.setUserPreferencesWindSpeedMetric);
  const setWindSpeed = useCentroStore((state) => state.setWeatherWindSpeedMetric);

  let userLoggedIn = "";
  if (sessionStorage.length === 0) {
    if (isAuthenticated === false) {
      userLoggedIn = "No";
    } else {
      userLoggedIn = "Yes";
    }
  } else {
    var authenticated = sessionStorage.getItem("isAuthenticated");
    if (authenticated === "true") {
      setAuth(true);

      var userIdStorage = sessionStorage.getItem("userId");
      setUserId(parseInt(userIdStorage));
      setActiveRole(userData?.data?.data.roleId); // Setting Active Role to the current selected one
      // Setting up User preferences
      setUserPreferencesUserId(parseInt(userIdStorage));
      setUserPreferencesPreferredLanguage(userData?.data?.data.userPreference.preferredLanguage);
      if (userData?.data?.data.userPreference.preferredLanguage === "Spanish") { // Setting English Language
        setEnglishLanguage("");
      } else {
        setEnglishLanguage(true);
      }
      setUserPreferencesDefaultFacilityId(userData?.data?.data.userPreference.defaultFacilityId);
      setUserPreferencesDisplayReportsFirst(userData?.data?.data.userPreference.displayReportsFirst);
      setUserPreferencesDisplayAnalyticsFirst(userData?.data?.data.userPreference.displayAnalyticsFirst);
      setUserPreferencesWeatherTempMetric(userData?.data?.data.userPreference.weatherTempMetric);
      if (userData?.data?.data.userPreference.weatherTempMetric === true) { // Setting Temp Metric
        setTemperature("");
      } else {
        setTemperature(true);
      }
      setUserPreferencesWindSpeedMetric(userData?.data?.data.userPreference.windSpeedMetric);
      if (userData?.data?.data.userPreference.windSpeedMetric === true) { // Setting Wind Speed
        setWindSpeed("");
      } else {
        setWindSpeed(true);
      }

      userLoggedIn = "Yes";
    } else {
      userLoggedIn = "No";
    }
  }
  // const theme = useCentroStore((state) => state.theme);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#55819b", // replace with your desired primary color
      },
      secondary: {
        main: "#a8bfcc", // replace with your desired primary color
      },
    },
  });
  const handleWindowClose = (e) => {
    setAuth(false);
    setUserId(0);
    queryClient.clear();
    // sessionStorage.clear();
  };


  useEffect(() => {
    const isAuthenticatedFromStorage = sessionStorage.getItem("isAuthenticated");
    const userIdFromStorage = sessionStorage.getItem("userId");

    if (isAuthenticatedFromStorage === "true" && userIdFromStorage) {
      setAuth(true);
      setUserId(parseInt(userIdFromStorage, 10));
    }

    window.addEventListener("beforeunload", handleWindowClose);

    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, []);

  const [isLoginFormVisible, setIsLoginFormVisible] = useState(false);

  const handleRouteChange = (pathname) => {
    setIsLoginFormVisible(pathname === '/');
  };

  const loginFormClass = isLoginFormVisible ? 'login-form-visible' : '';

  useEffect(() => {
    const hostname = window.location.hostname;
  
    if (hostname === 'localhost') {
      setServerText('Local Server');
    } else if (hostname.includes('development')) {
      setServerText('Development Server');
    } else if (hostname.includes('test')) {
      setServerText('Test Server');
    } else if (hostname === 'centro.infinaweb.com') {
      setServerText('Prod Server');
    } else if (hostname.includes('demo')) {
        setServerText('Demo Server');
    }
  }, []);
  

  return (
    <ThemeProvider theme={theme}>
      <Router>
      <RouteStatus onRouteChange={handleRouteChange} />
        <div className={`App ${theme} ${userLoggedIn} ${loginFormClass}`} id="dark">
          <MediaQuery maxWidth={1367}>
            <SpeedDialTooltipOpen />
          </MediaQuery>
          <MediaQuery maxWidth={650}>
            <FixedBottomNavigation />
          </MediaQuery>
          <Grid container className="Yes" flexDirection="row" flexWrap="nowrap">
            {/*  Business Hours */}

            {/* {isAuthenticated === true ? (
            <Grid item>
              
                <Navbar />
                <SessionTimeout/>
                <RefreshToken/>
                </Grid>
          ) : (
              <Grid item>

              </Grid>
          )}  */}

            {/*  After Hours */}

            {isAuthenticated === true ? (
              <Grid item>
                <Navbar />
                <SessionTimeout /> 
                <RefreshToken />
              </Grid>
            ) : (
              <Grid item>
                {/* <Navbar /> */}
              </Grid>
            )}

{isAuthenticated === true && userData?.data?.data.resetRequired === false ? (
              
              <Grid item className="main-wrap">
              <div className="main-content">
                <Routes>
                  <Route path="/" exact element={<LoginForm />}></Route>
                </Routes>
                <Routes>
                  <Route path="/home" exact 
                   element={userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).isAdmin === true ? <UserList /> : <Home theme={theme} />}></Route>
                </Routes>
                <Routes>
                  <Route path="/settings" exact element={<Settings />}></Route>
                </Routes>
                <Routes>
                  <Route path="/incident-reports" exact 
                   element={userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).isAdmin === true ? <UserList /> : <IncidentReports />}></Route>
                </Routes>
                <Routes>
                  <Route path="/user-list" exact 
                   element={userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].readUser === 1 ? <UserList /> : <Home />}></Route>
                </Routes>
                <Routes>
                  <Route path="/role-list" exact 
                   element={userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].readRole === 1 ? <RoleList /> : <Home />}></Route>
                </Routes>
                <Routes>
                  <Route path="/facility-list" exact 
                   element={userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].readFac === 1 ? <FacilityList /> : <Home />}></Route>
                </Routes>
                <Routes>
                  <Route path="/facility-type" 
                   element={userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].readFac === 1 ? <FacilityType /> : <Home />}></Route>
                </Routes>
                <Routes>
                  <Route path="/create-new-incident-report" exact element={<CreateNewIncidentReport />}></Route>
                </Routes>
                <Routes>
                  <Route path="/training" exact element={<Training />}></Route>
                </Routes>
                <Routes>
                  <Route path="/analytics" exact 
                   element={userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].displayAnalytics ===  0 
                       ? <UserList /> : <Analytics />}></Route>
                </Routes>
                <Routes>
                  <Route path="/setup" exact element={<SetUp />}></Route>
                </Routes>
                <Routes>
                  <Route path="/faq" exact element={<FAQ />}></Route>
                </Routes>
                {/* <Routes>
                  <Route path="/maps" exact element={<Maps />}></Route>
                </Routes> */}
                <Routes>
                  <Route path="/technical" exact element={<Technical />}></Route>
                </Routes>
                <Routes>
                  <Route path="/menu" exact element={<MenuSetup />}></Route>
                </Routes>
                <Routes>
                  <Route path="/user-settings" exact element={<UserSettings />}></Route>
                </Routes>
                <Routes>
                  <Route path="/user-profile" exact element={<UserProfile />}></Route>
                </Routes>
              </div>
            </Grid>  ) : (
              
              <Grid item className="main-wrap">
              <div className="main-content">
                <Routes>
                  <Route path="/" exact element={<LoginForm />}></Route>
                </Routes>
                <Routes>
                  <Route path="/home" exact element={<UserProfile theme={theme} />}></Route>
                </Routes>
                <Routes>
                  <Route path="/settings" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/incident-reports" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/user-list" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/role-list" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/facility-list" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/facility-type" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/create-new-incident-report" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/training" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/analytics" exact element={<Analytics />}></Route>
                </Routes>
                <Routes>
                  <Route path="/setup" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/faq" exact element={<UserProfile />}></Route>
                </Routes>
                {/* <Routes>
                  <Route path="/maps" exact element={<Maps />}></Route>
                </Routes> */}
                <Routes>
                  <Route path="/technical" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/menu" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/user-settings" exact element={<UserProfile />}></Route>
                </Routes>
                <Routes>
                  <Route path="/user-profile" exact element={<UserProfile />}></Route>
                </Routes>
              </div>
            </Grid>
            )}

            

            {/* {userloggedin === "Yes" ? (
             <div className="switch">
             <label> {theme === "dark-theme" ? "Dark Mode" : "Light Mode"}</label>
             <ReactSwitch onChange={toggleTheme} checked={theme === "dark-theme"} />
           </div>
          ) : (
            <div className="switch">
            </div>
              
          )}   */}
          </Grid>
        </div>
      </Router>
      <div style={{
      position: 'absolute',
      bottom: '2rem',
      left: '5rem',
      color: '#fff', // Change this to whatever color you want the text to be
      zIndex: "10000",
      textTransform: "uppercase"
    }}>
      {serverText}
    </div>
    </ThemeProvider>
  );
}

export default App;
